<template>
	<div v-if="options">
		<div class="my-4">
			<h5>Trigger</h5>
			<triggers-list :disabled="disabled" :config="config" v-model="options.trigger"></triggers-list>
		</div>

		<div class="my-4">
			<h5>Number of picks</h5>
			<div class="bg-light border p-3">
				<div class="w-25">
					<multiplier :disabled="disabled" v-model="options.picks" :min="0"></multiplier>
				</div>
			</div>
		</div>

		<div class="my-4">
			<h5>Add last losing pick?</h5>
			<div class="bg-light border p-3">
				<div class="w-25">
					<select :disabled="disabled" v-model="options.losingPick" class="form-control">
						<option :value="true">Yes</option>
						<option :value="false">No</option>
					</select>
				</div>
			</div>
		</div>

		<div class="my-4">
			<div class="row">
				<div class="col">
					<h5>Payouts</h5>
					<payouts :config="config" :disabled="disabled" v-model="options.payouts"></payouts>
				</div>
				<div class="col">
					<div class="sticky-top-secondary">
						<div>
							<h5>Summary</h5>
							<payouts-summary :config="config" class="bg-light border p-3" :payouts="options.payouts"></payouts-summary>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Trigger from "./trigger";
	import Multiplier from "../prize/multiplier";
	import Payouts from "./payouts";
	import PayoutsSummary from "./payouts-summary";
	import TriggersList from "./triggers-list";

	export default {
		components: {TriggersList, PayoutsSummary, Payouts, Multiplier, Trigger},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		mounted() {
			if (this.options === null) {
				this.options = {
					trigger: [],
					payouts: [],
					picks: 0,
					losingPick: false
				}
			}

			if (this.options && this.options.losingPick === undefined) {
				this.$nextTick(() => this.$set(this.options, "losingPick", false))
			}
		},
		computed: {
			options: {
				get() {
					return this.value
				},
				set(options) {
					console.log('options', options)
					this.$emit('input', options)
				}
			}
		}
	}
</script>
